import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getBonusData } from '../../store/reducers/bonus';
import { formatCurrency } from '../../utils/formatCurrency';
import { useBonusActions } from '../../hooks/useBonusActions';
import { BONUS_MODEL_WAGER, BONUS_TYPE_FREE, MODAL_DEPOSIT, MODAL_WITHDRAW } from '../../variables';

export const withBonuses = (PageComponent) => {
    const WithBonuses = ({ ...props }) => {
        const dispatch = useDispatch();
        const router = useRouter();
        const tr = useSelector((state) => state.global.data.translations);
        const { handleClaim, handleCancel, handleDepositToClaim } = useBonusActions();

        useEffect(() => {
            //preventing calling bonuses when deposit or withdrawal modal is opened
            ![MODAL_DEPOSIT, MODAL_WITHDRAW].includes(router.query.modal) && dispatch(getBonusData(true));
        }, []);

        const getSubtitle = (bonus) => {
            const wageringText = tr[`bonus.subtitle.${bonus.model}`];
            const text =
                bonus.model === BONUS_MODEL_WAGER
                    ? wageringText.replace('##WAGER_VALUE##', bonus.wager?.multiplier)
                    : wageringText;
            return bonus.type === BONUS_TYPE_FREE
                ? `${tr['bonus.subtitle.free.amount'].replace(
                    '##AMOUNT##',
                    formatCurrency(bonus.initialAmount, bonus.currency)
                )}; ${text}`
                : text;
        };

        return (
            <PageComponent
                {...props}
                handleClaim={handleClaim}
                handleDepositToClaim={handleDepositToClaim}
                handleCancel={handleCancel}
                getSubtitle={getSubtitle}
            />
        );
    };

    return WithBonuses;
};

import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dataLayerPush from '@tlf-e/brand-utils/dist/helpers/dataLayerPush';
import openCustomerSupportWidget from '@tlf-e/brand-utils/dist/helpers/openCustomerSupportWidget';
import StatusModal from '../../../StatusModal';
import { cleanRemoveBonusError } from '../../../../../store/reducers/bonus';
import { MODAL_STATUS_ERROR } from '../../../../../variables';

const CancelClaimedBonusErrorModal = ({ tr, removeBonusFailed }) => {
    const dispatch = useDispatch();
    const { removeBonusId, removeBonusErrorMessage } = useSelector((state) => state.bonus);
    const errorMessage =
        removeBonusErrorMessage.errors[0]?.detail === 'This provider doesn\'t allow cancellation of free rounds in progress.'
            ? tr['bonus-cancellation-error-provider-freespins']
            : tr['modal.bonus.error.content'];

    useEffect(() => {
        if (removeBonusFailed) {
            dataLayerPush({
                event: 'Bonus_Cancel_Error',
                bonus_id: removeBonusId || 'null_ID',
                error_msg: removeBonusErrorMessage
            });
        }
    }, [removeBonusFailed]);

    const handleModalClose = () => {
        dispatch(cleanRemoveBonusError());
    };

    const handleContactSupport = () => {
        openCustomerSupportWidget();
        handleModalClose();
    };

    return (
        <StatusModal
            isOpen={removeBonusFailed}
            status={MODAL_STATUS_ERROR}
            title={tr['modal.bonus.error.title']}
            onClose={handleModalClose}
            content={
                <Fragment>
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                </Fragment>
            }
        >
            <div className="container-btns">
                <button
                    type="button"
                    className="btn btn-submit btn-large btn-modern"
                    data-testid="open_support_widget"
                    onClick={handleContactSupport}
                >
                    {tr['modal.bonus.error.support']}
                </button>
                <button type="button" className="btn btn-cancel btn-large btn-modern" onClick={handleModalClose}>
                    {tr['modal.bonus.error.close']}
                </button>
            </div>
        </StatusModal>
    );
};

export default CancelClaimedBonusErrorModal;

import FreeBonusOffered from './FreeBonusOffered';
import FreeRoundsBonusOffered from './FreeRoundsBonusOffered';

const bonusesOffered = {
    deposit: (props) => <FreeBonusOffered {...props} />,
    free: (props) => <FreeBonusOffered {...props} />,
    free_rounds: (props) => <FreeRoundsBonusOffered {...props} />
};

const OfferedBonusItem = ({ bonus, getSubtitle, claimBonusId, ...props }) => {
    return bonusesOffered[bonus.type]({
        ...props,
        bonus,
        subtitle: getSubtitle(bonus),
        isLoading: claimBonusId === bonus.id
    });
};

export default OfferedBonusItem;

import { useSelector } from 'react-redux';

export const useBonusOfferAllowedData = () => {
    const { isAuth, information } = useSelector((state) => state.user);
    const { bonusOfferAllowed, id } = information;
    const isUserInformationLoaded = isAuth && id;
    const isPromoDataAllowed = isUserInformationLoaded ? bonusOfferAllowed : true;
    const isBonusDataAllowed = isUserInformationLoaded && bonusOfferAllowed;

    return {
        isPromoDataAllowed,
        isBonusDataAllowed,
    };
};

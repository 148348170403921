import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { setGameRouterHistory } from '../store/reducers/games';
import { getGamePath } from '../utils/locationConfig';

export const useFreeRoundBonusGame = (bonusGameId) => {
    const gamesData = useSelector((state) => state.games.data);
    const game = Array.isArray(bonusGameId) && gamesData.games.find((game) => game.id === bonusGameId[0]);
    const dispatch = useDispatch();
    const router = useRouter();

    const gameRedirect = () => {
        dispatch(setGameRouterHistory(router));
        const path = getGamePath(game, gamesData, router.locale);
        router.push(path);
    };

    return {
        game,
        gameRedirect
    };
};

import { imageBaseUrl } from '../../../../services/http';
import { BONUS_MODEL_DIRECT } from '../../../../variables';

const FreeBonusIcon = ({ bonus }) => {
    const bonusIconKey = bonus.model === BONUS_MODEL_DIRECT ? 'green' : 'blue';

    return (
        <div className="bonus-card--icon" id={`icon-${bonusIconKey}`}>
            <img src={`${imageBaseUrl}/diamond_${bonusIconKey}.png`} alt={`diamond_${bonusIconKey}`} />
        </div>
    );
};

export default FreeBonusIcon;

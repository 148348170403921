import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../../utils/formatCurrency';

const BonusDepositLimits = ({ bonus, messageMin = '', messageMax = '' }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const hasClaimingConditions =
        bonus.claimingEventCondition?.depositAmountMin || bonus.claimingEventCondition?.depositAmountMax;

    if (!hasClaimingConditions) {
        return null;
    }

    return (
        <div className="bonus-card--limits">
            {parseFloat(bonus.claimingEventCondition?.depositAmountMin) > 0 && (
                <span>
                    {messageMin || tr['bonus.deposit_limits.min']} -{' '}
                    {formatCurrency(bonus.claimingEventCondition.depositAmountMin, bonus.currency)}
                </span>
            )}
            {parseFloat(bonus.claimingEventCondition?.depositAmountMax) > 0 && (
                <span>
                    {messageMax || tr['bonus.deposit_limits.max']} -{' '}
                    {formatCurrency(bonus.claimingEventCondition.depositAmountMax, bonus.currency)}
                </span>
            )}
        </div>
    );
};

export default BonusDepositLimits;

import WageringProgress from './WageringProgress';
import FreeBonusIcon from '../FreeBonusIcon';
import { BONUS_MODEL_UPPER, BONUS_MODEL_WAGER } from '../../../../../variables';

const FreeBonusClaimed = ({ bonus, openCancelBonusModal, isLoading, tr }) => {
    return (
        <div className="bonus-card">
            <div>
                <FreeBonusIcon bonus={bonus} />
                <div className="bonus-card--status">{tr['bonus.status.title.claimed']}</div>
                <div className="bonus-card--title">{bonus.displayName || bonus.name}</div>
                {[BONUS_MODEL_UPPER, BONUS_MODEL_WAGER].includes(bonus.model) && <WageringProgress bonus={bonus} />}
            </div>
            <div>
                <div className="bonus-card--claim-bonus">
                    <button
                        className="btn btn-modern btn-decline"
                        data-testid={`cancel_${bonus.id}`}
                        onClick={openCancelBonusModal}
                        disabled={isLoading}
                    >
                        {isLoading ? tr['global.loading'] : tr['btn.cancel']}
                    </button>
                </div>
                <div className="bonus-card--description">{tr[`bonus.description.${bonus.type}`]}</div>
            </div>
        </div>
    );
};

export default FreeBonusClaimed;

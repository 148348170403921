const FreeRoundsBonusIcon = ({ game }) => {
    const gameThumbnail = game?.metadata.thumbnailBackground;

    if (!gameThumbnail) {
        return null;
    }

    return (
        <div className="bonus-card--icon bonus-card--icon-free-rounds">
            <img src={game.metadata.thumbnailBackground} className="game-image" alt={`game_${game.id}`} />
        </div>
    );
};

export default FreeRoundsBonusIcon;

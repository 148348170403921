import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import dayjs from 'dayjs';
import { formatCurrency } from '../../../../../utils/formatCurrency';

const WageringProgress = ({ bonus }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const leftToWager = bonus.initialAmount * bonus.wager?.multiplier * (1 - bonus.wager?.progressPercent / 100);
    const [progress, setProgress] = useState(0);
    const validTo = useMemo(
        () => dayjs(bonus.validTo, { utc: true }).tz(dayjs.tz.guess()).format('DD/MM/YYYY'),
        [bonus.validTo]
    );

    useEffect(() => {
        setProgress(bonus.wager?.progressPercent || 0);
    }, [bonus.wager?.progressPercent]);

    return (
        <div className="bonus-card--progress">
            {leftToWager >= 0 && (
                <div
                    className="progress-label"
                    dangerouslySetInnerHTML={{
                        __html: tr['bonus.wagering_progress.left_to_wager'].replace(
                            '##AMOUNT##',
                            `<strong>${formatCurrency(leftToWager, bonus.currency, 2)}</strong>`
                        )
                    }}
                />
            )}
            <LinearProgress
                value={progress}
                variant="determinate"
                classes={{
                    root: 'modern progress-bonus'
                }}
            />
            <div className="progress-info">
                <span>
                    {tr['bonus.wagering_progress.bonus_amount'].replace(
                        '##AMOUNT##',
                        formatCurrency(bonus.initialAmount, bonus.currency)
                    )}
                </span>
                {validTo && <span>{tr['bonus.wagering_progress.bonus_expires'].replace('##DATE##', validTo)}</span>}
            </div>
        </div>
    );
};

export default WageringProgress;

import FreeBonusIcon from '../FreeBonusIcon';
import BonusDepositLimits from '../BonusDepositLimits';
import { BONUS_CLAIMING_EVENT_DEPOSIT } from '../../../../../variables';

const FreeBonusOffered = ({ bonus, subtitle, handleClaim, handleDepositToClaim, isLoading, tr }) => {
    const cta =
        bonus.claimingEvent === BONUS_CLAIMING_EVENT_DEPOSIT
            ? tr['bonus.claiming_action.deposit_to_claim']
            : tr['bonus.claiming_action.claim'];

    const onClaim = (e) =>
        bonus.claimingEvent === BONUS_CLAIMING_EVENT_DEPOSIT ? handleDepositToClaim(bonus.id) : handleClaim(e, bonus.id);

    return (
        <div className="bonus-card">
            <div>
                <FreeBonusIcon bonus={bonus} />
                <div className="bonus-card--title">{bonus.displayName || bonus.name}</div>
                <div className="bonus-card--subtitle">{subtitle}</div>
                <BonusDepositLimits bonus={bonus} />
            </div>
            <div>
                <div className="bonus-card--claim-bonus">
                    <button
                        className="btn btn-submit btn-modern"
                        data-testid={`claim_${bonus.id}`}
                        onClick={onClaim}
                        disabled={isLoading}
                    >
                        {isLoading ? tr['global.loading'] : cta}
                    </button>
                </div>
                <div className="bonus-card--description">{tr[`bonus.description.${bonus.type}`]}</div>
            </div>
        </div>
    );
};

export default FreeBonusOffered;

import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusModal from '../../../StatusModal';
import { setModalCancelBonusId } from '../../../../../store/reducers/bonus';
import { MODAL_STATUS_WARNING } from '../../../../../variables';

const CancelClaimedBonusModal = ({ tr, handleCancel }) => {
    const { modalCancelBonusId } = useSelector((state) => state.bonus);
    const dispatch = useDispatch();

    const handleModalClose = () => {
        dispatch(setModalCancelBonusId(null));
    };

    useEffect(() => {
        dispatch(setModalCancelBonusId(null));
    }, []);

    return (
        <StatusModal
            isOpen={!!modalCancelBonusId}
            status={MODAL_STATUS_WARNING}
            title={tr['modal.bonus.bonus_removal']}
            onClose={handleModalClose}
            content={
                <Fragment>
                    <div dangerouslySetInnerHTML={{ __html: tr['modal.bonus.title'] }} />
                    <div dangerouslySetInnerHTML={{ __html: tr['modal.bonus.content'] }} />
                </Fragment>
            }
        >
            <div className="container-btns">
                <button type="button" className="btn btn-cancel btn-large btn-modern" onClick={handleModalClose}>
                    {tr['modal.bonus.no']}
                </button>
                <button
                    type="button"
                    className="btn btn-submit btn-large btn-modern"
                    data-testid="remove_bonus"
                    onClick={(e) => {
                        handleCancel(e, modalCancelBonusId);
                        handleModalClose();
                    }}
                >
                    {tr['modal.bonus.yes']}
                </button>
            </div>
        </StatusModal>
    );
};

export default CancelClaimedBonusModal;

import FreeRoundsBonusIcon from '../FreeRoundsBonusIcon';
import BonusDepositLimits from '../BonusDepositLimits';
import { useFreeRoundBonusGame } from '../../../../../hooks/useFreeRoundBonusGame';
import { BONUS_CLAIMING_EVENT_DEPOSIT } from '../../../../../variables';

const FreeRoundsBonusOffered = ({ bonus, subtitle, handleDepositToClaim, handleClaim, isLoading, tr }) => {
    const { roundCount, gameId } = bonus.freeRounds;
    const { game, gameRedirect } = useFreeRoundBonusGame(gameId);
    const bonusName = tr['bonus.free_rounds_offered.name']
        .replace('##ROUNDS_COUNT##', roundCount)
        .replace('##NAME##', bonus.displayName || bonus.name);
    const cta =
        bonus.claimingEvent === BONUS_CLAIMING_EVENT_DEPOSIT
            ? tr['bonus.claiming_action.deposit_to_claim']
            : tr['bonus.claiming_action.claim'];

    const onClaim = (e) =>
        bonus.claimingEvent === BONUS_CLAIMING_EVENT_DEPOSIT
            ? handleDepositToClaim(bonus.id)
            : handleClaim(e, bonus.id, { onSuccess: gameRedirect });

    return (
        <div className="bonus-card">
            <div>
                <FreeRoundsBonusIcon game={game} />
                <div className="bonus-card--title">{bonusName}</div>
                <div className="bonus-card--subtitle">{subtitle}</div>
                <BonusDepositLimits bonus={bonus} />
            </div>
            <div>
                <div className="bonus-card--claim-bonus">
                    <button
                        className="btn btn-modern btn-submit"
                        disabled={!game || isLoading}
                        data-testid={`claim_${bonus.id}`}
                        onClick={onClaim}
                    >
                        {isLoading ? tr['global.loading'] : cta}
                    </button>
                </div>
                <div className="bonus-card--description">{tr[`bonus.description.${bonus.type}`]}</div>
            </div>
        </div>
    );
};

export default FreeRoundsBonusOffered;

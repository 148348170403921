import { useDispatch, useSelector } from 'react-redux';
import { claimBonus, removeBonusAction } from '../store/reducers/bonus';
import { getModalUrl } from '../utils/locationConfig';
import { selectBonus } from '../store/reducers/bank';
import { useRouter } from 'next/router';
import { MODAL_DEPOSIT } from '../variables';

export const useBonusActions = () => {
    const { claimBonusId, removeBonusId } = useSelector((state) => state.bonus);
    const dispatch = useDispatch();
    const router = useRouter();

    const handleClaim = (e, id, callbacks) => {
        claimBonusId ? e.preventDefault() : dispatch(claimBonus(id, callbacks));
    };

    const handleCancel = (e, id) => {
        removeBonusId ? e.preventDefault() : dispatch(removeBonusAction(id));
    };

    const handleDepositToClaim = (bonusId, { onSuccess } = {}) => {
        const { href, as } = getModalUrl(MODAL_DEPOSIT, router);
        router.push(href, as);
        dispatch(selectBonus(bonusId));
        //Mystery Chests claiming
        onSuccess && onSuccess();
    };

    return {
        handleClaim,
        handleCancel,
        handleDepositToClaim
    };
};

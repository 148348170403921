import FreeRoundsBonusIcon from '../FreeRoundsBonusIcon';
import { useFreeRoundBonusGame } from '../../../../../hooks/useFreeRoundBonusGame';
import WageringProgress from './WageringProgress';

const FreeRoundsBonusClaimed = ({ bonus, openCancelBonusModal, isLoading, tr }) => {
    const { freeRounds, displayName, name, initialAmount, type, id } = bonus;
    const { roundCount, gameId } = freeRounds;
    const { game, gameRedirect } = useFreeRoundBonusGame(gameId);
    const bonusName = `${roundCount} free spins in ${displayName || name}`;
    const isFreeRoundPlayed = !!initialAmount;
    const bonusDescription = isFreeRoundPlayed
        ? tr['bonus.description.played.free.rounds']
        : tr[`bonus.description.${type}`];

    return (
        <div className="bonus-card">
            <div>
                <FreeRoundsBonusIcon game={game} />
                <div className="bonus-card--status">{tr['bonus.status.title.claimed']}</div>
                <div className="bonus-card--title">{bonusName}</div>
                {isFreeRoundPlayed && <WageringProgress bonus={bonus} />}
            </div>
            <div>
                <div className="bonus-card--claim-bonus">
                    <button
                        className="btn btn-modern btn-decline"
                        data-testid={`cancel_${id}`}
                        onClick={openCancelBonusModal}
                        disabled={isLoading}
                    >
                        {isLoading ? tr['global.loading'] : tr['btn.cancel']}
                    </button>
                    {!isFreeRoundPlayed && (
                        <button
                            className="btn btn-modern btn-submit"
                            data-testid={`game_redirect_${id}`}
                            onClick={gameRedirect}
                            disabled={!game}
                        >
                            {tr['btn.continue_playing']}
                        </button>
                    )}
                </div>
                <div className="bonus-card--description">
                    {bonusDescription}
                </div>
            </div>
        </div>
    );
};

export default FreeRoundsBonusClaimed;

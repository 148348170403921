import FreeBonusClaimed from './FreeBonusClaimed';
import FreeRoundsBonusClaimed from './FreeRoundsBonusClaimed';

const bonusesClaimed = {
    deposit: (props) => <FreeBonusClaimed {...props} />,
    free: (props) => <FreeBonusClaimed {...props} />,
    free_rounds: (props) => <FreeRoundsBonusClaimed {...props} />
};

const ClaimedBonusItem = ({ bonus, removeBonusId, getSubtitle, ...props }) => {
    return bonusesClaimed[bonus.type]({
        ...props,
        bonus,
        subtitle: getSubtitle(bonus),
        isLoading: bonus.id === removeBonusId
    });
};

export default ClaimedBonusItem;
